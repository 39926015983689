import React, { useEffect, useState, useMemo } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { FormGroup, Label, Input, Form, Button, Card, Row, Col, Spinner, Table } from 'reactstrap';
import Select from 'react-select';
import translate from 'translate';
import {
    INGREDIENT_CATEGORIES,
    INGREDIENT_CATEGORIES_SUBSCRIPTION,
    INTOLERANCES,
    INTOLERANCES_SUBSCRIPTION,
} from 'queries';

const foodAllergens = Object.freeze([
    Object.freeze({ value: 1, label: '1' }),
    Object.freeze({ value: 2, label: '2' }),
    Object.freeze({ value: 3, label: '3' }),
    Object.freeze({ value: 4, label: '4' }),
    Object.freeze({ value: 5, label: '5' }),
    Object.freeze({ value: 6, label: '6' }),
    Object.freeze({ value: 7, label: '7' }),
    Object.freeze({ value: 8, label: '8' }),
    Object.freeze({ value: 9, label: '9' }),
    Object.freeze({ value: 10, label: '10' }),
    Object.freeze({ value: 11, label: '11' }),
    Object.freeze({ value: 12, label: '12' }),
    Object.freeze({ value: 13, label: '13' }),
    Object.freeze({ value: 14, label: '14' }),
]);

const typeOptions = Object.freeze([
    Object.freeze({ value: 'solid', label: 'solid' }),
    Object.freeze({ value: 'liquid', label: 'liquid' }),
]);

export default function IngredientForm(props) {
    const { onClose, onSave, ingredientId, ingredientData, ingredientLoading, languages } = props;

    const [category, setCategory] = useState(null);
    const [type, setType] = useState(typeOptions[0]);
    const [defaultDaysUntilExpiry, setDefaultDaysUntilExpiry] = useState(2);
    const [intolerances, setIntolerances] = useState([]);
    const [allergens, setAllergens] = useState([]);
    const [averagePrice, setAveragePrice] = useState(0);

    const [fats, setFats] = useState(0);
    const [fiber, setFiber] = useState(0);
    const [kcal, setKcal] = useState(0);
    const [proteins, setProteins] = useState(0);
    const [saccharides, setSaccharides] = useState(0);
    const [sugars, setSugars] = useState(0);

    const [visible, setVisible] = useState(true);

    const [translations, setTranslations] = useState([]);
    const [translating, setTranslating] = useState(false);
    const [originalTranslation, setOriginalTranslation] = useState(null);

    const [fixed, setFixed] = useState(false);
    const [verified, setVerified] = useState(false);
    const [problematic, setProblematic] = useState(false);

    const {
        data: ingredientCategoriesData,
        loading: ingredientCategoriesLoading,
        refetch: ingredientCategoriesRefetch,
    } = useQuery(INGREDIENT_CATEGORIES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(INGREDIENT_CATEGORIES_SUBSCRIPTION, {
        onData: () => {
            ingredientCategoriesRefetch();
        },
    });

    const {
        data: intolerancesData,
        loading: intolerancesLoading,
        refetch: intolerancesRefetch,
    } = useQuery(INTOLERANCES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(INTOLERANCES_SUBSCRIPTION, {
        onData: () => {
            intolerancesRefetch();
        },
    });

    useEffect(() => {
        if (ingredientData && ingredientData.ingredient) {
            setTranslations(
                languages.map((item) => ({
                    language: item,
                    title:
                        ingredientData.ingredient.translations.find(
                            (translation) => translation.language.id === item.id
                        )?.title ?? '',
                    allAlternatives:
                        ingredientData.ingredient.translations.find(
                            (translation) => translation.language.id === item.id
                        )?.allAlternatives ?? '',
                }))
            );
            setType(
                typeOptions.find((item) => item.value === ingredientData.ingredient.type) ??
                    typeOptions[0]
            );
            setDefaultDaysUntilExpiry(ingredientData.ingredient.defaultDaysUntilExpiry ?? 2);

            setCategory(
                ingredientData.ingredient.category
                    ? {
                          label: ingredientData.ingredient.category.translations
                              .map(
                                  (translation) =>
                                      `${translation.title} (${translation.language.languageCode})`
                              )
                              .join(', '),
                          value: ingredientData.ingredient.category.id,
                      }
                    : null
            );
            setIntolerances(
                ingredientData.ingredient.intolerances
                    ? ingredientData.ingredient.intolerances.map((intolerance) => ({
                          label: intolerance.translations
                              .map(
                                  (translation) =>
                                      `${translation.title} (${translation.language.languageCode})`
                              )
                              .join(', '),
                          value: intolerance.id,
                      }))
                    : []
            );
            setAllergens(
                ingredientData.ingredient && ingredientData.ingredient.allergens
                    ? foodAllergens.filter((item) =>
                          ingredientData.ingredient.allergens.includes(item.value)
                      )
                    : []
            );
            setFats(ingredientData.ingredient.fats);
            setFiber(ingredientData.ingredient.fiber);
            setKcal(ingredientData.ingredient.kcal);
            setProteins(ingredientData.ingredient.proteins);
            setSaccharides(ingredientData.ingredient.saccharides);
            setSugars(ingredientData.ingredient.sugars);
            setVisible(ingredientData.ingredient.visible);
            setAveragePrice(ingredientData.ingredient.averagePrice ?? 0);

            setFixed(ingredientData.ingredient.fixed);
            setVerified(ingredientData.ingredient.verified);
            setProblematic(ingredientData.ingredient.problematic);
        } else if (languages) {
            setTranslations(
                languages.map((item) => ({
                    language: item,
                    title: '',
                    allAlternatives: '',
                }))
            );

            setType(typeOptions[0]);
            setDefaultDaysUntilExpiry(2);
            setCategory(null);
            setIntolerances([]);
            setAllergens([]);
            setFats(0);
            setFiber(0);
            setKcal(0);
            setProteins(0);
            setSaccharides(0);
            setSugars(0);
            setVisible(true);
            setAveragePrice(0);

            setFixed(false);
            setVerified(false);
            setProblematic(false);
        }
    }, [ingredientData, languages]);

    async function getOtherTitles(original) {
        if (!original) {
            return;
        }

        let newTranslations = [...translations];

        for (let index = 0; index < translations.length; index++) {
            const element = translations[index];
            if (element.language.id !== original.language.id) {
                let translatedTitle = await translate(original.title, {
                    from: original.language.languageCode,
                    to: element.language.languageCode,
                });
                newTranslations[index] = {
                    ...element,
                    title: translatedTitle,
                };
            }
        }

        setTranslations(newTranslations);
        setTranslating(false);
        setOriginalTranslation(null);
    }

    const ingredientCategoriesOptions = useMemo(() => {
        return ingredientCategoriesData && ingredientCategoriesData.ingredientCategories
            ? ingredientCategoriesData.ingredientCategories.map((ingredientCategory) => ({
                  value: ingredientCategory.id,
                  label: ingredientCategory.translations
                      .map(
                          (translation) =>
                              `${translation.title} (${translation.language.languageCode})`
                      )
                      .join(', '),
              }))
            : [];
    }, [ingredientCategoriesData]);

    const intolerancesOptions = useMemo(() => {
        return intolerancesData && intolerancesData.intolerances
            ? intolerancesData.intolerances.map((intolerance) => ({
                  value: intolerance.id,
                  label: intolerance.translations
                      .map(
                          (translation) =>
                              `${translation.title} (${translation.language.languageCode})`
                      )
                      .join(', '),
              }))
            : [];
    }, [intolerancesData]);

    if (ingredientLoading || ingredientCategoriesLoading || intolerancesLoading) {
        return (
            <Card style={{ padding: '1em' }}>
                <Spinner color="primary" size="sm" />
            </Card>
        );
    }

    return (
        <Card style={{ padding: '1em' }}>
            <Row>
                <Form>
                    <FormGroup>
                        <Label htmlFor="trending-group-title">Názov</Label>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Jazyk</th>
                                    <th>Preklad</th>
                                </tr>
                            </thead>
                            <tbody>
                                {translations.map((translation) => (
                                    <>
                                        <tr key={translation.language.id}>
                                            <td>{`${translation.language.title} (${translation.language.languageCode})`}</td>
                                            <td>
                                                {translating &&
                                                    translation.language.id !==
                                                        originalTranslation.language.id && (
                                                        <Spinner />
                                                    )}
                                                {(!translating ||
                                                    translation.language.id ===
                                                        originalTranslation.language.id) && (
                                                    <>
                                                        <Input
                                                            id={`ingredient_translation_for_${translation.languageCode}`}
                                                            disabled={translating}
                                                            value={translation.title}
                                                            onChange={(e) => {
                                                                setTranslations(
                                                                    translations.map((item) => {
                                                                        if (
                                                                            item.language.id ===
                                                                            translation.language.id
                                                                        ) {
                                                                            return {
                                                                                ...item,
                                                                                title: e.target
                                                                                    .value,
                                                                            };
                                                                        }
                                                                        return item;
                                                                    })
                                                                );
                                                            }}
                                                        />
                                                        {translation.title.length > 0 && (
                                                            <Button
                                                                color="link"
                                                                onClick={() => {
                                                                    setTranslating(true);
                                                                    setOriginalTranslation(
                                                                        translation
                                                                    );
                                                                    getOtherTitles(translation);
                                                                }}
                                                            >
                                                                Preložiť podľa tohto prekladu
                                                            </Button>
                                                        )}
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <Input
                                                    id={`ingredient_alternative_titles_for_${translation.languageCode}`}
                                                    disabled={translating}
                                                    placeholder={`${translation.language.languageCode} - Alternatívne názvy / slang / nárečie`}
                                                    value={translation.allAlternatives}
                                                    onChange={(e) => {
                                                        setTranslations(
                                                            translations.map((item) => {
                                                                if (
                                                                    item.language.id ===
                                                                    translation.language.id
                                                                ) {
                                                                    return {
                                                                        ...item,
                                                                        allAlternatives:
                                                                            e.target.value,
                                                                    };
                                                                }
                                                                return item;
                                                            })
                                                        );
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </Table>
                    </FormGroup>
                    <table style={{ marginBottom: '1em' }}>
                        <tbody>
                            <tr>
                                <th width="25%">AI Rating</th>{' '}
                                <td>{ingredientData?.ingredient?.aiRating}</td>
                            </tr>
                            {ingredientData?.ingredient?.sources.map((source) => (
                                <tr key={source.id}>
                                    <th>{`ID: ${source.id}`}</th>{' '}
                                    <td>
                                        <a href={source.url} target="_blank" rel="noreferrer">
                                            {source.url}
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {ingredientData?.ingredient?.sources.length === 0 && <p>Žiadne zdroje</p>}
                    <FormGroup check style={{ marginBottom: '1em' }}>
                        <Input
                            id="ingredient-problematic"
                            type="checkbox"
                            checked={problematic}
                            onChange={() => {
                                if (verified || fixed) {
                                    setVerified(false);
                                    setFixed(false);
                                }
                                setProblematic(!problematic);
                            }}
                        />
                        <Label check htmlFor="ingredient-problematic">
                            Ingrediencia je problematická
                        </Label>
                    </FormGroup>

                    <FormGroup check style={{ marginBottom: '1em' }}>
                        <Input
                            id="ingredient-fixed"
                            type="checkbox"
                            checked={fixed}
                            onChange={() => {
                                if (verified || problematic) {
                                    setVerified(false);
                                    setProblematic(false);
                                }
                                setFixed(!fixed);
                            }}
                        />
                        <Label check htmlFor="ingredient-fixed">
                            Ingrediencia bola opravená
                        </Label>
                    </FormGroup>

                    <FormGroup check style={{ marginBottom: '1em' }}>
                        <Input
                            id="ingredient-verified"
                            type="checkbox"
                            checked={verified}
                            onChange={() => {
                                if (fixed || problematic) {
                                    setProblematic(false);
                                    setFixed(false);
                                }
                                setVerified(!verified);
                            }}
                        />
                        <Label check htmlFor="ingredient-verified">
                            Ingrediencia je schválená
                        </Label>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="average-price">
                            Priemerná cena v obchodoch na 100g/100ml
                        </Label>
                        <Input
                            id={`average-price`}
                            value={averagePrice}
                            style={{}}
                            step={0.1}
                            min={0}
                            type="number"
                            onChange={(e) => {
                                setAveragePrice(e.target.value);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="ingredient-type">Typ</Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={false}
                            isSearchable={true}
                            name="ingredient-type"
                            options={typeOptions}
                            value={type}
                            onChange={(e) => {
                                setType(e);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="ingredient-category">Kategória *</Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={false}
                            isSearchable={true}
                            name="ingredient-category"
                            options={ingredientCategoriesOptions}
                            value={category}
                            onChange={(e) => {
                                setCategory(e);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="ingredient-allergens">Alergény</Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={false}
                            isSearchable={true}
                            isMulti
                            name="ingredient-allergens"
                            options={foodAllergens}
                            value={allergens}
                            onChange={(e) => {
                                setAllergens(e);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="ingredient-intolerances">Intolerancie</Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={false}
                            isSearchable={true}
                            isMulti
                            name="ingredient-intolerances"
                            options={intolerancesOptions}
                            value={intolerances}
                            onChange={(e) => {
                                setIntolerances(e);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="default-expiry">Predvolený čas expirácie (dni)</Label>
                        <Input
                            id={`default-expiry`}
                            value={defaultDaysUntilExpiry}
                            style={{}}
                            step={1}
                            min={0}
                            type="number"
                            onChange={(e) => {
                                setDefaultDaysUntilExpiry(e.target.value);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="nutritional-values">
                            Nutričné hodnoty na 100g/100ml/1ks
                        </Label>
                        <Table>
                            <tbody>
                                <tr>
                                    <th>Kcal</th>
                                    <td>
                                        <Input
                                            id={`nutritional-values-kcal`}
                                            value={kcal}
                                            style={{}}
                                            step={10}
                                            min={0}
                                            type="number"
                                            onChange={(e) => {
                                                setKcal(e.target.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Sacharidy</th>
                                    <td>
                                        <Input
                                            id={`nutritional-values-saccharides`}
                                            value={saccharides}
                                            style={{}}
                                            step={10}
                                            min={0}
                                            type="number"
                                            onChange={(e) => {
                                                setSaccharides(e.target.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Z toho cukry</th>
                                    <td>
                                        <Input
                                            id={`nutritional-values-sugars`}
                                            value={sugars}
                                            style={{}}
                                            step={10}
                                            min={0}
                                            type="number"
                                            onChange={(e) => {
                                                setSugars(e.target.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Tuky</th>
                                    <td>
                                        <Input
                                            id={`nutritional-values-fats`}
                                            value={fats}
                                            style={{}}
                                            step={10}
                                            min={0}
                                            type="number"
                                            onChange={(e) => {
                                                setFats(e.target.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Bielkoviny</th>
                                    <td>
                                        <Input
                                            id={`nutritional-values-proteins`}
                                            value={proteins}
                                            style={{}}
                                            step={10}
                                            min={0}
                                            type="number"
                                            onChange={(e) => {
                                                setProteins(e.target.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Vláknina</th>
                                    <td>
                                        <Input
                                            id={`nutritional-values-fiber`}
                                            value={fiber}
                                            style={{}}
                                            step={10}
                                            min={0}
                                            type="number"
                                            onChange={(e) => {
                                                setFiber(e.target.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </FormGroup>

                    <FormGroup check style={{ marginBottom: '1em' }}>
                        <Input
                            id="ingredient-visible"
                            type="checkbox"
                            checked={visible}
                            onChange={() => {
                                setVisible(!visible);
                            }}
                        />
                        <Label check htmlFor="ingredient-visible">
                            Zobrazovať ingredienciu kuchárom a používateľom
                        </Label>
                    </FormGroup>
                </Form>
            </Row>

            <Row xs="2">
                <Col>
                    {onClose && (
                        <Button color="secondary" block onClick={onClose}>
                            Zrušiť
                        </Button>
                    )}
                </Col>
                <Col>
                    <Button
                        color="primary"
                        block
                        disabled={
                            translations.some((translation) => translation.title.length === 0) ||
                            !category
                        }
                        onClick={() => {
                            onSave({
                                id: ingredientId,
                                type: type.value,
                                defaultDaysUntilExpiry: parseInt(defaultDaysUntilExpiry),
                                translations,
                                categoryId: category.value,
                                intolerances,
                                allergens,
                                fats,
                                fiber,
                                kcal,
                                proteins,
                                saccharides,
                                sugars,
                                visible,
                                averagePrice,
                                problematic,
                                fixed,
                                verified,
                            });
                        }}
                    >
                        Uložiť
                    </Button>
                </Col>
            </Row>
        </Card>
    );
}
