import { gql } from '@apollo/client';

export const INGREDIENTS_SUBSCRIPTION = gql`
    subscription ingredientsSubscription {
        ingredientsSubscription
    }
`;

export const INGREDIENTS = gql`
    query ingredients($search: String, $languageId: Int, $limit: Int, $offset: Int) {
        ingredients(search: $search, languageId: $languageId, limit: $limit, offset: $offset) {
            id
            translations {
                title
                language {
                    id
                }
            }
            kcal
            fats
            proteins
            allergens
            averagePrice
            bulkPrice
            fiber
            memberPrice
            saccharides
            problematic
            salePrice
            sugars
            verified
            fixed
            aiRating
        }
    }
`;

export const INGREDIENT = gql`
    query ingredient($id: Int!) {
        ingredient(id: $id) {
            id
            type
            defaultDaysUntilExpiry
            translations {
                title
                allAlternatives
                language {
                    id
                    languageCode
                }
            }
            category {
                id
                translations {
                    language {
                        id
                        languageCode
                    }
                    title
                }
            }
            intolerances {
                id
                translations {
                    title
                    language {
                        id
                        languageCode
                    }
                }
            }
            averagePrice
            allergens
            fats
            fiber
            kcal
            proteins
            saccharides
            sugars
            visible
            fixed
            verified
            problematic
            aiRating
            sources {
                id
                url
            }
        }
    }
`;

export const ADD_INGREDIENT = gql`
    mutation addIngredient(
        $allergens: [Int!]!
        $categoryId: Int!
        $fats: Float
        $fiber: Float
        $intoleranceIds: [Int!]!
        $kcal: Int!
        $proteins: Float
        $saccharides: Float
        $sugars: Float
        $translations: [IngredientTranslationInput!]!
        $visible: Boolean!
        $averagePrice: Float
        $type: IngredientTypeEnum!
        $defaultDaysUntilExpiry: Int!
    ) {
        addIngredient(
            allergens: $allergens
            categoryId: $categoryId
            fats: $fats
            fiber: $fiber
            intoleranceIds: $intoleranceIds
            kcal: $kcal
            proteins: $proteins
            saccharides: $saccharides
            sugars: $sugars
            translations: $translations
            visible: $visible
            averagePrice: $averagePrice
            type: $type
            defaultDaysUntilExpiry: $defaultDaysUntilExpiry
        ) {
            id
        }
    }
`;

export const UPDATE_INGREDIENT = gql`
    mutation updateIngredient(
        $id: Int!
        $allergens: [Int!]!
        $categoryId: Int!
        $fats: Float
        $fiber: Float
        $intoleranceIds: [Int!]!
        $kcal: Int!
        $proteins: Float
        $saccharides: Float
        $sugars: Float
        $translations: [IngredientTranslationInput!]!
        $visible: Boolean!
        $averagePrice: Float
        $type: IngredientTypeEnum!
        $defaultDaysUntilExpiry: Int!
        $problematic: Boolean
        $fixed: Boolean
        $verified: Boolean
    ) {
        updateIngredient(
            id: $id
            allergens: $allergens
            categoryId: $categoryId
            fats: $fats
            fiber: $fiber
            intoleranceIds: $intoleranceIds
            kcal: $kcal
            proteins: $proteins
            saccharides: $saccharides
            sugars: $sugars
            translations: $translations
            visible: $visible
            averagePrice: $averagePrice
            type: $type
            defaultDaysUntilExpiry: $defaultDaysUntilExpiry
            problematic: $problematic
            fixed: $fixed
            verified: $verified
        ) {
            id
        }
    }
`;

export const DELETE_INGREDIENT = gql`
    mutation deleteIngredient($id: Int!) {
        deleteIngredient(id: $id) {
            id
        }
    }
`;
